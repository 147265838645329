<template>
  <div class="container">
    <Header></Header>
    <div class="pictures">
      <el-carousel indicator-position="outside" height="500px">
        <el-carousel-item v-for="(image, index) in images" :key="index">
          <el-image fit="cover" :src="image" style="width: 100%; height: 100%;" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <div class="date">
        <el-row type="flex" style="border: 1px solid #dedede; padding: 0 10px 0 20px;">
          <el-col :span="8">
            <div class="arrival">
              <div class="label">{{ $t('checkin') }}</div>
              <div class="action" style="cursor: pointer;" @click="showArrival">{{ arrival | formatDate }}</div>
              <el-date-picker
                ref="arrival"
                v-model="arrival"
                style="height: 0; border: none; padding: 0; overflow: hidden;"
                value-format="yyyy-MM-dd"
                :picker-options="arrivalOptions"
                @change="onArrivalChanged"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8" style="padding-left: 40px;">
            <div class="departure">
              <div class="label">{{ $t('checkout') }}</div>
              <div class="action" style="cursor: pointer;" @click="showDeparture">{{ departure | formatDate }}</div>
              <el-date-picker
                ref="departure"
                v-model="departure"
                style="height: 0; border: none; padding: 0; overflow: hidden;"
                value-format="yyyy-MM-dd"
                :picker-options="departureOptions"
                @change="onDepartureChanged"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
            <div class="guests">
              <div class="label">{{ $t('guests') }}</div>
              <div class="action">
                <el-input-number v-model="guests" :min="1" :max="10"></el-input-number>
              </div>
            </div>
            <div>
              <el-button type="warning" size="large" @click="search">{{ $t('search') }}</el-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="list">
        <div class="list-item" v-for="(hotel, index) of hotels" :key="index">
          <el-row type="flex">
            <el-col :span="6">
              <el-image style="width: 210px; height: 156px" lazy fit="cover" :src="hotel.image" />
            </el-col>
            <el-col :span="18">
              <div class="hotel-name">
                <div class="name">{{ hotel.name }}</div>
                <div class="rate">{{ hotel.currency_code }}{{ hotel.rate_amount | formatNumber }}</div>
              </div>
              <div class="address">{{ hotel.address }}</div>
              <div class="show-more">
<!--                <el-rate-->
<!--                  v-model="hotel.star"-->
<!--                  text-color="#ff9900"-->
<!--                  :max="hotel.star"-->
<!--                  disabled-->
<!--                />-->
                <el-button type="warning" size="small" @click="showMore(hotel)">{{ $t('showMore') }}</el-button>
              </div>
            </el-col>
          </el-row>

          <el-divider />
        </div>
      </div>

      <div style="flex: 1;"></div>
      <Footer/>
    </div>

    <el-dialog :title="$t('confirm')" :visible.sync="showNotify" width="400px" :close-on-click-modal="false">
      <el-row :gutter="20" class="table-row">
        <el-col :span="12">
          <span>{{ $t('checkin') }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ arrival | formatDate }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="table-row">
        <el-col :span="12">
          <span>{{ $t('checkout') }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ departure | formatDate }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="table-row">
        <el-col :span="12">
          <span>{{ $t('guests') }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ guests }}</span>
        </el-col>
      </el-row>

      <el-row class="table-row">
        <span :class="{ 'color-red': nights < 2 }">{{ $t('reservationNightsMessage') }}</span>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showNotify = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="goHotelDetail" :disabled="nights < 2">{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { Notify } from 'vant'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { formatDate } from '@/filters'
import dayjs from '@/utils/dayjs'
import { getSystemDate } from '@/api/system'

export default {
  name: 'HotelList',
  components: {
    Header,
    Footer
  },
  data () {
    const today = dayjs().startOf('date').format('YYYY-MM-DD')
    return {
      showNotify: false,
      showDate: false,
      selectedHotel: null,
      today,
      arrival: today,
      departure: today,
      guests: 1,
      minDate: today,
      maxDate: today,
      // dateRange: [],
      images: [
        `/server-static/banner01.png?v=${Date.now()}`,
        `/server-static/banner02.png?v=${Date.now()}`,
        `/server-static/banner03.png?v=${Date.now()}`
      ],
      hotels: []
    }
  },
  computed: {
    arrivalOptions () {
      const _this = this
      return {
        disabledDate (time) {
          return dayjs(time).format('YYYY-MM-DD') >= _this.maxDate || dayjs(time).format('YYYY-MM-DD') < _this.minDate
        }
      }
    },
    departureOptions () {
      const _this = this
      return {
        disabledDate (time) {
          return dayjs(time).format('YYYY-MM-DD') > _this.maxDate || dayjs(time).format('YYYY-MM-DD') <= _.max([_this.minDate, _this.arrival])
        }
      }
    },
    nights () {
      if (this.arrival && this.departure) {
        return dayjs(this.departure).diff(dayjs(this.arrival), 'day')
      }

      return 0
    }
  },
  async mounted () {
    try {
      const { data: serverToday } = await getSystemDate({ format: 'DATE' })
      this.today = serverToday
      // 获取可预订的范围
      const res = await this.$axios.get('/invitation/reservation-dates')
      this.minDate = res.data.reservation_begin_date < this.today ? this.today : res.data.reservation_begin_date
      this.maxDate = res.data.reservation_end_date

      this.arrival = this.minDate
      const nextDay = dayjs(this.arrival).add(2, 'day').format('YYYY-MM-DD')
      this.departure = nextDay <= this.maxDate ? nextDay : this.maxDate
      // this.dateRange = [this.arrival, this.departure]
      await this.getHotel()
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    showArrival () {
      this.$refs.arrival.focus()
    },
    showDeparture () {
      this.$refs.departure.focus()
    },
    onArrivalChanged (e) {
      this.arrival = e
      this.showDeparture()
    },
    onDepartureChanged (e) {
      this.departure = e
    },
    async search () {
      this.$router.replace({ query: { arrival: this.arrival, departure: this.departure, guests: this.guests } }).catch(() => { })
      await this.getHotel()
    },
    async getHotel () {
      this.$root.showLoading()
      const res = await this.$axios.get('/invitation/hotel-list')
      this.hotels = res.data
      this.$root.hideLoading()
    },
    showMore (hotel) {
      this.showNotify = true
      this.selectedHotel = hotel
    },
    goHotelDetail () {
      if (this.selectedHotel) {
        if (this.nights >= 2) {
          this.showNotify = false
          this.$router.push({
            name: 'HotelDetail',
            params: {
              hotelId: this.selectedHotel.id,
              arrival: formatDate(this.arrival),
              departure: formatDate(this.departure),
              guests: this.guests
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  margin: 0 auto;
}

.date {
  padding: 20px 10px;

  .label {
    color: #5c5c5c;
  }

  .action {
    padding-top: 20px;
  }

  .el-col {
    padding: 20px 0 20px 20px;
  }

  .el-col:not(:last-child) {
    border-right: 1px solid #dedede;
  }
}

.list {
  padding: 14px;

  .list-item:not(:first-child) {
    padding-top: 10px;
  }
}

.hotel-name {
  display: flex;
  flex-direction: row;
  .name {
    flex: 1;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 20px 0;
  }
}

.address {
  color: #5c5c5c;
  word-wrap: break-word;
  text-align: left;
}

.show-more {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* 隐藏星级 */
  align-items: center;
}

.table-row {
  padding: 6px 0;
}

.color-red {
  color: red;
}
</style>
